var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',[_vm._v("Novo Usuário")]),_c('v-form',{ref:"formCreateUser",on:{"submit":function($event){$event.preventDefault();return _vm.saveUser.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":"Nome","rules":[function (v) { return !!v || 'Preencha este campo'; }]},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('v-text-field',{attrs:{"label":"E-mail","rules":[function (v) { return !!v || 'Preencha este campo'; }]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('v-select',{attrs:{"items":[
        { text: 'Usuário', value: 'user' },
        { text: 'Gerente', value: 'manager' },
        { text: 'Admin', value: 'admin' },
        { text: 'Super Admin', value: 'superAdmin' } ],"item-text":"text","item-value":"value","label":"Tipo de usuário"},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('v-text-field',{attrs:{"label":"Senha","rules":[
        function (v) { return !!v || 'Preencha este campo'; },
        function (v) { return v.length >= 8 || 'A senha deve ter pelo menos 8 caracters'; } ],"type":_vm.showPassword ? 'text' : 'password',"append-icon":!_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('v-text-field',{attrs:{"label":"Repetir Senha","rules":[
        function (v) { return !!v || 'Preencha este campo'; },
        function (v) { return v === _vm.user.password || 'As senhas não conferem'; } ],"type":_vm.showPasswordConfirmation ? 'text' : 'password',"append-icon":!_vm.showPasswordConfirmation ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.showPasswordConfirmation = !_vm.showPasswordConfirmation}},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","type":"submit"}},[_vm._v("Salvar")]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.$router.push({ name: 'users.index' })}}},[_vm._v("Voltar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }