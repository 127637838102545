<template>
  <div class="container">
    <h1>Novo Usuário</h1>

    <v-form @submit.prevent="saveUser" ref="formCreateUser">
      <v-text-field
        label="Nome"
        :rules="[(v) => !!v || 'Preencha este campo']"
        v-model="user.name"
      ></v-text-field>

      <v-text-field
        label="E-mail"
        v-model="user.email"
        :rules="[(v) => !!v || 'Preencha este campo']"
      ></v-text-field>

      <v-select
        :items="[
          { text: 'Usuário', value: 'user' },
          { text: 'Gerente', value: 'manager' },
          { text: 'Admin', value: 'admin' },
          { text: 'Super Admin', value: 'superAdmin' },
        ]"
        item-text="text"
        item-value="value"
        v-model="user.role"
        label="Tipo de usuário"
      ></v-select>

      <v-text-field
        label="Senha"
        :rules="[
          (v) => !!v || 'Preencha este campo',
          (v) => v.length >= 8 || 'A senha deve ter pelo menos 8 caracters',
        ]"
        v-model="user.password"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="!showPassword ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append="showPassword = !showPassword"
      ></v-text-field>

      <v-text-field
        label="Repetir Senha"
        :rules="[
          (v) => !!v || 'Preencha este campo',
          (v) => v === user.password || 'As senhas não conferem',
        ]"
        v-model="passwordConfirmation"
        :type="showPasswordConfirmation ? 'text' : 'password'"
        :append-icon="!showPasswordConfirmation ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append="showPasswordConfirmation = !showPasswordConfirmation"
      ></v-text-field>

      <v-btn color="primary" class="mx-2" type="submit">Salvar</v-btn>
      <v-btn
        color="error"
        class="mx-2"
        @click="$router.push({ name: 'users.index' })"
        >Voltar</v-btn
      >
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      showPasswordConfirmation: false,
      loading: false,
      user: {
        name: "",
        email: "",
        password: "",
        role: "user",
      },
      passwordConfirmation: "",
    };
  },
  methods: {
    cleanForm() {
      this.user = {
        name: "",
        email: "",
        password: "",
        role: "user",
      };
    },
    async saveUser() {
      const form = this.$refs.formCreateUser;

      if (form.validate()) {
        this.loading = true;
        try {
          await this.$http.post("api/users", this.user);
          this.$toast.success("Salvo com sucesso");
          this.cleanForm();
          form.resetValidation();
        } catch (error) {
          this.$toast.error("Erro ao salvar");

          if (error.response) {
            let errors = Object.entries(error.response.data.errors);

            if (errors) {
              errors.forEach((e) => {
                this.$toast.error(e[1][0]);
              });
            }
          }
        }
        this.loading = false;
      }
    },
  },
};
</script>
